import React, { FC } from 'react'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import rootReducer from '../reducers'

const initialState = {}

const isBrowser = typeof window !== 'undefined'
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const windowAny = isBrowser ? (window as any) : (null as any)

const devtools =
    process.env.NODE_ENV === 'development' && windowAny?.devToolsExtension
        ? windowAny.__REDUX_DEVTOOLS_EXTENSION__ && windowAny.__REDUX_DEVTOOLS_EXTENSION__()
        : (f: unknown) => f

const middleware = [thunk]

const store = createStore(
    rootReducer,
    initialState,
    compose(applyMiddleware(...middleware), devtools)
)

const ReduxWrapper: FC = ({ children }) => <Provider store={store}>{children}</Provider>

export default ReduxWrapper
