import uniqBy from 'lodash/uniqBy'
import { ICartTool } from '../../../interfaces/cart-tool.interface'
import { fetchCartToolsFromLS, fetchComparedToolsFromLS } from '../../../utils/local-storage'
import { IToolsState } from '../../interfaces/state.interface'

const initialState: IToolsState = {
    comparedToolIds: typeof localStorage != 'undefined' ? fetchComparedToolsFromLS() : [],
    cartTools: typeof localStorage != 'undefined' ? fetchCartToolsFromLS() : [],
}

interface ISetComparedToolIdsAction {
    readonly type: 'SET_COMPARED_TOOL_IDS'
    readonly payload: number[]
}

interface IAddComparedToolIdAction {
    readonly type: 'ADD_COMPARED_TOOL_ID'
    readonly payload: number
}

interface IRemoveComparedToolIdAction {
    readonly type: 'REMOVE_COMPARED_TOOL_ID'
    readonly payload: number
}

interface ISetCartToolsAction {
    readonly type: 'SET_CART_TOOLS'
    readonly payload: ICartTool[]
}

interface IAddCartToolAction {
    readonly type: 'ADD_CART_TOOL'
    readonly payload: ICartTool
}

interface IRemoveCartToolAction {
    readonly type: 'REMOVE_CART_TOOL'
    readonly payload: number
}

export type ActionType =
    | ISetComparedToolIdsAction
    | IAddComparedToolIdAction
    | IRemoveComparedToolIdAction
    | ISetCartToolsAction
    | IAddCartToolAction
    | IRemoveCartToolAction

export default (state = initialState, action: ActionType): IToolsState => {
    switch (action.type) {
        case 'SET_COMPARED_TOOL_IDS':
            return { ...state, comparedToolIds: action.payload }
        case 'ADD_COMPARED_TOOL_ID': {
            return {
                ...state,
                comparedToolIds: [...state.comparedToolIds, action.payload],
            }
        }
        case 'REMOVE_COMPARED_TOOL_ID': {
            return {
                ...state,
                comparedToolIds: state.comparedToolIds.filter((id) => id !== action.payload),
            }
        }

        case 'SET_CART_TOOLS': {
            return {
                ...state,
                cartTools: action.payload,
            }
        }
        case 'ADD_CART_TOOL': {
            const { id, quantity } = action.payload
            return {
                ...state,
                cartTools: uniqBy([...state.cartTools, { id, quantity }], 'id'),
            }
        }
        case 'REMOVE_CART_TOOL': {
            return {
                ...state,
                cartTools: state.cartTools.filter(({ id }) => id !== action.payload),
            }
        }

        default:
            return state
    }
}
