/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import { navigate } from 'gatsby-link'
import { Auth0Provider } from '@auth0/auth0-react'
import React from 'react'
import ReduxWrapper from './src/state/ReduxWrapper'

import '@fontsource/open-sans/300.css'
import '@fontsource/open-sans/400.css'
import '@fontsource/open-sans/500.css'
import '@fontsource/open-sans/700.css'

const onRedirectCallback = (appState) => {
    // Use Gatsby's navigate method to replace the url
    navigate(appState?.returnTo || '/', { replace: true })
}

export const wrapRootElement = ({ element }) => {
    return (
        <ReduxWrapper>
            <Auth0Provider
                domain={process.env.GATSBY_AUTH0_DOMAIN}
                clientId={process.env.GATSBY_AUTH0_CLIENTID}
                redirectUri={window.location.origin}
                onRedirectCallback={onRedirectCallback}
            >
                {element}
            </Auth0Provider>
        </ReduxWrapper>
    )
}
