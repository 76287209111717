import * as Sentry from '@sentry/gatsby'
import uniq from 'lodash/uniq'
import { ICartTool } from '../interfaces/cart-tool.interface'

export enum ToolListEnum {
    COMPARED_TOOLS = 'compared-tools',
    CART_TOOLS = 'cart-tools',
}

const nullifyTools = (toolListType: ToolListEnum): void => {
    localStorage.setItem(toolListType, JSON.stringify([]))
}

function getFromLS<T>(key: string): T | null {
    const item = localStorage.getItem(key)
    if (!item) {
        console.warn(`Item "${key}" was not found in local storage`)
        return null
    }
    try {
        const result = JSON.parse(item)
        return result
    } catch (e) {
        Sentry.captureException(e)
        console.warn(`Problem with parsing local storage (key: ${key}): `, e)
        return null
    }
}

export const fetchComparedToolsFromLS = (): number[] => {
    const ids = getFromLS<number[]>(ToolListEnum.COMPARED_TOOLS)
    if (!ids) {
        nullifyTools(ToolListEnum.COMPARED_TOOLS)
    }
    return (ids || []) as number[]
}

export const fetchCartToolsFromLS = (): ICartTool[] => {
    const ids = getFromLS<ICartTool[]>(ToolListEnum.CART_TOOLS)
    if (!ids) {
        nullifyTools(ToolListEnum.CART_TOOLS)
    }
    return (ids || []) as ICartTool[]
}

interface ISaveCartToolToLSParams {
    readonly id: number
    readonly quantity: number
}

export const saveCartToolToLS = ({ id, quantity }: ISaveCartToolToLSParams): void => {
    const currentList = (getFromLS<ICartTool[]>(ToolListEnum.CART_TOOLS) || []) as ICartTool[]
    const currentWithoutChanged = currentList.filter((cartTool) => cartTool.id !== id)
    localStorage.setItem(
        ToolListEnum.CART_TOOLS,
        JSON.stringify([...currentWithoutChanged, { id, quantity }])
    )
}

export const saveComparedToolToLS = (id: number): void => {
    const currentList = (getFromLS(ToolListEnum.COMPARED_TOOLS) || []) as number[]
    localStorage.setItem(ToolListEnum.COMPARED_TOOLS, JSON.stringify(uniq([...currentList, id])))
}

export const removeCartToolFromLS = (id: number): void => {
    const currentList = (getFromLS<ICartTool[]>(ToolListEnum.CART_TOOLS) || []) as ICartTool[]
    localStorage.setItem(
        ToolListEnum.CART_TOOLS,
        JSON.stringify(currentList.filter(({ id: currentId }) => id !== currentId))
    )
}

export const removeComparedToolFromLS = (id: number): void => {
    const currentList = (getFromLS<number[]>(ToolListEnum.COMPARED_TOOLS) || []) as number[]
    localStorage.setItem(
        ToolListEnum.COMPARED_TOOLS,
        JSON.stringify(currentList.filter((currentId) => id !== currentId))
    )
}

export const replaceComparedToolsInLS = (ids: number[]): void => {
    localStorage.setItem(ToolListEnum.COMPARED_TOOLS, JSON.stringify(ids))
}

export const replaceCartToolsInLS = (cartTools: ICartTool[]): void => {
    localStorage.setItem(ToolListEnum.CART_TOOLS, JSON.stringify(cartTools))
}
