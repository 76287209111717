export enum FilterMetaTypeEnum {
    SINGLE = 'single',
    LIST = 'list',
    RANGE = 'range',
}

export interface IDependencyItem {
    readonly id: string
    readonly values?: string[]
}
export interface IFilterMeta {
    readonly id: string
    readonly label: string
    readonly type: FilterMetaTypeEnum
    readonly dependencies?: IDependencyItem[]
}

export interface IFiltersSubgroupMeta {
    readonly name: string
    readonly label?: string
    readonly filters: IFilterMeta[]
}

export interface IFiltersGroupMeta {
    readonly name: string
    readonly label: string
    readonly subgroups: IFiltersSubgroupMeta[]
}
