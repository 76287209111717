import _ from 'lodash'
import { FilterMetaTypeEnum, IFiltersGroupMeta } from '../../../interfaces/filters-meta.interface'
import { FilterValueType, IFilterValueListItem } from '../../../interfaces/filters.interface'
import { SortingMethodEnum, SortingOrderEnum } from '../../../interfaces/sorting.interface'
import { IAddOptionToFilterActionParams } from '../../actions/filters/add-option-to-filter'
import { IChangeFiltersValuesActionParams } from '../../actions/filters/change-filters-values'
import { IChangeRangeSliderFilterActionParams } from '../../actions/filters/change-range-slider-filter'
import { IRemoveOptionFromFilterActionParams } from '../../actions/filters/remove-option-from-filter'
import { IRemoveRangeFromFilterActionParams } from '../../actions/filters/remove-range-from-filter'
import { IFiltersState } from '../../interfaces/state.interface'

interface IFetchFiltersAction {
    readonly type: 'FETCH_FILTERS_META'
    readonly payload: IFiltersGroupMeta[]
}

interface IChangeFilterValuesAction {
    readonly type: 'CHANGE_FILTERS_VALUES'
    readonly payload: {
        readonly objectType: string
        readonly attribute: string
        readonly newValues: string[] | string | null
    }
}

interface IAddOptionToFilterAction {
    readonly type: 'ADD_OPTION_TO_FILTER'
    readonly payload: {
        readonly objectType: string
        readonly attribute: string
        readonly option: string
    }
}

interface IChangeRangeSliderFilterAction {
    readonly type: 'CHANGE_RANGE_SLIDER_FILTER'
    readonly payload: {
        readonly objectType: string
        readonly attribute: string
        readonly value: [number, number]
    }
}

interface IRemoveOptionFromFilterAction {
    readonly type: 'REMOVE_OPTION_FROM_FILTER'
    readonly payload: {
        readonly objectType: string
        readonly attribute: string
        readonly option: string
    }
}

interface IRemoveRangeFromFilterAction {
    readonly type: 'REMOVE_RANGE_FROM_FILTER'
    readonly payload: {
        readonly objectType: string
        readonly attribute: string
    }
}

interface IClearAllFiltersAction {
    readonly type: 'CLEAR_ALL_FILTERS'
}

interface IChangeSearchValueAction {
    readonly type: 'CHANGE_SEARCH_VALUE'
    readonly payload: string
}

interface IChangePageAction {
    readonly type: 'CHANGE_PAGE'
    readonly payload: number
}

interface IClearSearchValueAction {
    readonly type: 'CLEAR_SEARCH_VALUE'
}

interface IChangeSortingMethodAction {
    readonly type: 'CHANGE_SORTING_METHOD'
    readonly payload: {
        readonly newMethod: SortingMethodEnum
        readonly newProperty: string | null
        readonly newOrder: SortingOrderEnum
    }
}

export type ActionType =
    | IFetchFiltersAction
    | IChangeFilterValuesAction
    | IAddOptionToFilterAction
    | IChangeRangeSliderFilterAction
    | IRemoveOptionFromFilterAction
    | IRemoveRangeFromFilterAction
    | IClearAllFiltersAction
    | IChangeSearchValueAction
    | IChangePageAction
    | IClearSearchValueAction
    | IChangeSortingMethodAction

const initialState: IFiltersState = {
    meta: [],
    filtersValues: [],
    searchValue: '',
    page: 1,
    sorting: {
        method: SortingMethodEnum.RELEVANCE,
        order: SortingOrderEnum.DESC,
        property: null,
    },
}

const constructFullName = (objectType: string, attribute: string) => `${objectType}:${attribute}`

const getFilterDeps = (
    objectType: string,
    attribute: string,
    filtersGroupsMeta: IFiltersGroupMeta[]
) => {
    const allFiltersMeta = filtersGroupsMeta.flatMap(({ subgroups }) =>
        subgroups.flatMap(({ filters }) => filters)
    )
    return allFiltersMeta.filter(({ dependencies }) =>
        dependencies?.map(({ id }) => id).includes(`${objectType}:${attribute}`)
    )
}

const nullishFilterDeps = (
    objectType: string,
    attribute: string,
    filtersGroupsMeta: IFiltersGroupMeta[],
    currentFiltersValues: FilterValueType[]
) => {
    const currentDeps = getFilterDeps(objectType, attribute, filtersGroupsMeta)

    return currentDeps.reduce((acc, { id }) => {
        const [objectType, attribute] = id.split(':')
        return calculateChangeFiltersValues(
            acc,
            {
                objectType,
                attribute,
                newValues: null,
            },
            filtersGroupsMeta
        )
    }, currentFiltersValues)
}

const getNewValueByChangeParams = (
    params: IChangeFiltersValuesActionParams
): FilterValueType | null => {
    if (!params.newValues || params.newValues.length === 0) {
        return null
    }

    if (typeof params.newValues === 'string') {
        return {
            objectType: params.objectType,
            attribute: params.attribute,
            value: params.newValues,
            type: FilterMetaTypeEnum.SINGLE,
        }
    }

    return {
        objectType: params.objectType,
        attribute: params.attribute,
        values: params.newValues,
        type: FilterMetaTypeEnum.LIST,
    }
}

const calculateChangeFiltersValues = (
    currentFilterValues: FilterValueType[],
    params: IChangeFiltersValuesActionParams,
    filtersGroupsMeta: IFiltersGroupMeta[]
): FilterValueType[] => {
    const fullNames = currentFilterValues.map(({ objectType, attribute }) =>
        constructFullName(objectType, attribute)
    )
    const anotherFullName = constructFullName(params.objectType, params.attribute)

    const newFilterValue = getNewValueByChangeParams(params)

    if (!!newFilterValue && !fullNames.includes(anotherFullName)) {
        return [...currentFilterValues, newFilterValue as FilterValueType]
    }

    const isEmptyValue = newFilterValue === null

    const newCurrentFiltersValues = currentFilterValues
        .map((filterValue) => {
            const { objectType, attribute } = filterValue
            if (constructFullName(objectType, attribute) === anotherFullName) {
                if (isEmptyValue) {
                    return null
                }

                return newFilterValue
            }

            return filterValue
        })
        .filter((filterValue) => filterValue !== null) as FilterValueType[]

    if (!isEmptyValue) {
        return newCurrentFiltersValues
    }

    const { objectType, attribute } = params

    return nullishFilterDeps(objectType, attribute, filtersGroupsMeta, newCurrentFiltersValues)
}

const calculateAddOptionFiltersValues = (
    currentFilterValues: FilterValueType[],
    params: IAddOptionToFilterActionParams
): FilterValueType[] => {
    const fullNames = currentFilterValues.map(({ objectType, attribute }) =>
        constructFullName(objectType, attribute)
    )
    const anotherFullName = constructFullName(params.objectType, params.attribute)

    return fullNames.includes(anotherFullName)
        ? currentFilterValues.map((filterValue) => {
              const { objectType, attribute } = filterValue
              if (constructFullName(objectType, attribute) === anotherFullName) {
                  const castedFilterValue = filterValue as IFilterValueListItem
                  return {
                      objectType,
                      attribute,
                      values: _.union(castedFilterValue.values, [params.option]),
                      type: FilterMetaTypeEnum.LIST,
                  }
              }

              return filterValue
          })
        : [
              ...currentFilterValues,
              {
                  objectType: params.objectType,
                  attribute: params.attribute,
                  values: [params.option],
                  type: FilterMetaTypeEnum.LIST,
              },
          ]
}

const calculateRemoveOptionFiltersValues = (
    currentFiltersValues: FilterValueType[],
    params: IRemoveOptionFromFilterActionParams,
    filtersGroupsMeta: IFiltersGroupMeta[]
): FilterValueType[] => {
    const anotherFullName = constructFullName(params.objectType, params.attribute)

    let isEmptyValue = false

    const newCurrentFiltersValues = currentFiltersValues.reduce(
        (acc: FilterValueType[], filterValue: FilterValueType) => {
            const { objectType, attribute, type } = filterValue
            if (constructFullName(objectType, attribute) === anotherFullName) {
                if (type === FilterMetaTypeEnum.SINGLE) {
                    isEmptyValue = true
                    return acc
                }

                const castedFilterValue = filterValue as IFilterValueListItem
                const newValues: string[] = castedFilterValue.values.filter(
                    (option) => option !== params.option
                )

                if (newValues.length === 0) {
                    isEmptyValue = true
                    return acc
                }

                const newFilterValue: IFilterValueListItem = {
                    objectType,
                    attribute,
                    values: newValues,
                    type: FilterMetaTypeEnum.LIST,
                }

                return [...acc, newFilterValue]
            }

            return [...acc, filterValue]
        },
        []
    )

    if (!isEmptyValue) {
        return newCurrentFiltersValues
    }

    const { objectType, attribute } = params

    return nullishFilterDeps(objectType, attribute, filtersGroupsMeta, newCurrentFiltersValues)
}

const calculateRemoveRangeFiltersValues = (
    currentFilterValues: FilterValueType[],
    params: IRemoveRangeFromFilterActionParams
): FilterValueType[] => {
    return currentFilterValues.filter(
        ({ objectType, attribute }) =>
            objectType !== params.objectType || attribute !== params.attribute
    )
}

const calculateChangeRangeSliderFiltersValues = (
    currentFilterValues: FilterValueType[],
    params: IChangeRangeSliderFilterActionParams
): FilterValueType[] => {
    const fullNames = currentFilterValues.map(({ objectType, attribute }) =>
        constructFullName(objectType, attribute)
    )
    const anotherFullName = constructFullName(params.objectType, params.attribute)

    return fullNames.includes(anotherFullName)
        ? currentFilterValues.map((filterValue) => {
              const { objectType, attribute } = filterValue
              if (constructFullName(objectType, attribute) === anotherFullName) {
                  return {
                      objectType,
                      attribute,
                      from: params.value[0],
                      to: params.value[1],
                      type: FilterMetaTypeEnum.RANGE,
                  }
              }

              return filterValue
          })
        : [
              ...currentFilterValues,
              {
                  objectType: params.objectType,
                  attribute: params.attribute,
                  from: params.value[0],
                  to: params.value[1],
                  type: FilterMetaTypeEnum.RANGE,
              },
          ]
}

export default (state = initialState, action: ActionType): IFiltersState => {
    switch (action.type) {
        case 'FETCH_FILTERS_META':
            return { ...state, meta: action.payload }
        case 'CHANGE_FILTERS_VALUES': {
            return {
                ...state,
                page: 1,
                filtersValues: calculateChangeFiltersValues(
                    state.filtersValues,
                    action.payload,
                    state.meta
                ),
            }
        }
        case 'ADD_OPTION_TO_FILTER': {
            return {
                ...state,
                page: 1,
                filtersValues: calculateAddOptionFiltersValues(state.filtersValues, action.payload),
            }
        }
        case 'REMOVE_OPTION_FROM_FILTER': {
            return {
                ...state,
                page: 1,
                filtersValues: calculateRemoveOptionFiltersValues(
                    state.filtersValues,
                    action.payload,
                    state.meta
                ),
            }
        }
        case 'REMOVE_RANGE_FROM_FILTER': {
            return {
                ...state,
                page: 1,
                filtersValues: calculateRemoveRangeFiltersValues(
                    state.filtersValues,
                    action.payload
                ),
            }
        }
        case 'CHANGE_RANGE_SLIDER_FILTER': {
            return {
                ...state,
                page: 1,
                filtersValues: calculateChangeRangeSliderFiltersValues(
                    state.filtersValues,
                    action.payload
                ),
            }
        }
        case 'CLEAR_ALL_FILTERS': {
            return {
                ...state,
                page: 1,
                filtersValues: [],
            }
        }
        case 'CHANGE_SEARCH_VALUE': {
            return {
                ...state,
                page: 1,
                searchValue: action.payload,
            }
        }
        case 'CHANGE_PAGE': {
            return {
                ...state,
                page: action.payload,
            }
        }
        case 'CLEAR_SEARCH_VALUE': {
            return {
                ...state,
                page: 1,
                searchValue: '',
            }
        }
        case 'CHANGE_SORTING_METHOD': {
            return {
                ...state,
                sorting: {
                    order: action.payload.newOrder,
                    method: action.payload.newMethod,
                    property: action.payload.newProperty,
                },
            }
        }
        default:
            return { ...state }
    }
}
